<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    //- loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row#page-head.d-flex.align-center.justify-md-space-between
      v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='3' md='3')
        h1.my-0 {{$t('GPT_MENU.UPLOADSTATUS')}}
      v-col(cols='9').pr-0
          v-row.d-flex.justify-end
            v-col(cols='5').d-flex.align-center.justify-md-space-between
              v-select(v-model='selectKnowledge' :title='getItemNameFromListById(Knowledges_list,selectKnowledge)' :items='Knowledges_list' item-value='id' item-text='name' prepend-inner-icon='mdi-book-open-variant' height='30' flat dense solo)
              refreshBtn(@EmitRefresh='onEmitRefresh')
    #page-inner.scroll-x.pb-0.height_100vh
      .upload_page_height.scrollbar.px-3()
          v-container.pa-1.upload_page-container(max-content)
           v-data-table.dashboard-table.rounded-card.pl-3.elevation-2(
              style='text-align:start;'
              item-key="id",
              v-model="selectedRows" 
              :item-class="rowClass"
              :headers="fieldsHeader",
              :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
              :items="uploadStatusList",
              :items-per-page="itemPerPage",
              :page.sync="page",
              @page-count="pageCount = $event",
              multi-sort
              :search="search",
              fixed-header,
              hide-default-footer="hide-default-footer",
              dense
              height='74vh'
              :loading='loading'
              loading-text='Loading . . .'
            )
              template( v-slot:item.method="{ item }" )
                  .ellipsis(:title='getI18nName(item.method)' height='36px')
                    span {{getI18nName(item.method)}}
              template( v-slot:item.status="{ item }" )
                  .ellipsis(:title='getI18nName(item.status)')
                    span {{getI18nName(item.status)}}
              template( v-slot:item.created_on="{ item }" )
                  .ellipsis(:title='getFormatTime(item.created_on)')
                    span {{getFormatTime(item.created_on)}}
              template( v-slot:item.updated_on="{ item }" )
                  .ellipsis(:title='getFormatTime(item.updated_on)')
                    span {{getFormatTime(item.updated_on)}}
              template( v-slot:item.type="{ item }" )
                  .ellipsis(:title='getI18nName(item.type)')
                    span {{getI18nName(item.type)}}
              template( v-slot:item.file_name="{ item }" )
                  .ellipsis(:title='item.file_name')
                    span {{item.file_name == "WEB" && item.type == "WEB" ? $t('UPGPT.WEBSITEANALYSIS'):item.file_name}}
              template( v-slot:item.knowledge_name="{ item }")
                  .ellipsis(v-if='item.type == "Auto Sync"' :title='item.owner_last_name+ " " + (item.owner_first_name? item.owner_first_name+" ":"" ) +$t("GPT_UPLOAD.AutoKnow")')
                    span {{item.owner_last_name}} {{item.owner_first_name? item.owner_first_name:""}} {{$t("GPT_UPLOAD.AutoKnow")}}
                  .ellipsis(v-if='item.type == "UploadImage"' :title='$t("UPGPT.UPLOADIMG")')
                    span {{item.knowledge_name == null ? $t('GPT_UPLOAD.DEL_K'):item.knowledge_name}}
                  .ellipsis(v-else :title='item.knowledge_name == null ? $t("GPT_UPLOAD.DEL_K") : item.knowledge_name')
                    span {{item.knowledge_name == null ? $t('GPT_UPLOAD.DEL_K'):item.knowledge_name}}
              template( v-slot:item.total="{ item }" )
                  .ellipsis(:title='item.total')
                    span {{item.total}}
              template( v-slot:item.success="{ item }" )
                  .ellipsis(:title='item.success')
                    span {{item.success}}
              template( v-slot:item.fail="{ item }" )
                  .ellipsis(:title='item.fail')
                    span {{item.fail}}
              template( v-slot:item.comment="{ item }" )
                  v-btn( icon @click="showRecords(item.comment)" height="40" color="primary" depressed dark)
                    v-icon mdi-tooltip-text
                    //- span {{$t('UPLOADSTATUS.SHOW')}}

          v-pagination.mt-3(v-model='page' :length='pageCount' circle :total-visible="10" v-if='!loading')
    v-dialog(
        v-model="recordsDialog",
        max-width="500",
        content-class="setting-dialog",
        v-if="recordsDialog === true"
      )
        v-card
          v-card-text
            v-textarea.mt-4.textarea-style(
              id='textarea_id',
              v-model="records",
              :label="$t('UPLOADSTATUS.NO_RECORD')"
              solo
              no-resize
              readonly
              height='500'
            )
            v-snackbar(v-model="snackbar" :timeout="1000" 
              absolute
              centered
              color="primary"
            ) {{$t("IMPORT_EXPORT.COPY_2") }}
            v-card-actions.px-0.py-0
              v-row(no-gutters)
                v-col.pr-2(cols="6")
                  v-btn(
                    v-if='records.length>0'
                    :ripple="false",
                    @click="onCopyMsg",
                    width="100%",
                    height="40",
                    color="primary",
                    depressed,
                    dark
                  ) {{$t('IMPORT_EXPORT.COPY')}}
                  
                v-col.pr-2(:cols="records.length>0? '6':'12'")
                  v-btn(
                    :ripple="false",
                    @click="onCancel",
                    width="100%",
                    height="40",
                    color="green",
                    depressed,
                    dark
                  ) {{$t('GENERAL.CONFIRM')}}
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    
</template>

<script>
import refreshBtn from "@/components/Common/refreshBtn";
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import KnowledgeLibraryService from "../services/KnowledgeLibraryService";
import UpGPTUploadStatusDataService from "@/services/UpGPTUploadStatusDataService";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    refreshBtn,
  },
  data() {
    return {
      // other
      loading:false,
      isDelete:false,
      // Dialog
      addDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      Knowledges_list:[{id:"0",name:i18n.t('GENERAL.ALL')}],
      search:"",
      selectKnowledge:"0",
      selectedRows:[],
      recordsDialog:false,
      records:null,
      snackbar:false,
      uploadStatusList:[],
      fieldsHeader: [
        // { value: "data-table-select",align: "center",width: '50px',},
        // { text: i18n.t('UPLOADSTATUS.METHOD'), value: "method", align: "left" , width: '20%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.TYPE'), value: "type", align: "left" , width: '25%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.CREATE_TIME'), value: "created_on", align: "left" , width: '35%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.UPDATED_TIME'), value: "updated_on", align: "left" , width: '35%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.FILE_NAME'), value: "file_name", align: "left" , width: '30%' , sortable: true, },
        { text: i18n.t('GPT_MENU.KNOWLEDGE'), value: "knowledge_name", align: "left" , width: '30%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.TOTAL'), value: "total", align: "center" , width: '15%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.SUCCESS'), value: "success", align: "center" , width: '15%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.FAIL'), value: "fail", align: "center" , width: '15%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.STATUS'), value: "status", align: "center" , width: '20%' , sortable: true, },
        { text: i18n.t('UPLOADSTATUS.COMMENT'), value: "comment", align: "center" , width: '25%' , sortable: false, },
      ],
      page: 1,
      pageCount: 1,
      itemPerPage: 13,

    };
  },
  async created() {
    this.loading = true;
    await this.getKnowledgeList();
    await this.getListData();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    async getKnowledgeList(){
      await KnowledgeLibraryService.select_list().then((response)=>{
        this.Knowledges_list = this.Knowledges_list.concat(response.data);
      })
    },
    async getListData(){
      this.loading = true;
      await UpGPTUploadStatusDataService.list(this.selectKnowledge).then((response)=>{
        this.uploadStatusList = response.data;
        console.log(this.uploadStatusList)
        this.loading = false;
      });
    },
    showRecords(data){
      this.recordsDialog = true;
      this.records = data;
    },
    onCancel() {
      this.recordsDialog = false;
      this.records = null;
    },
    onCopyMsg(event){      
        const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
        clipboardData.writeText(this.records);
        this.snackbar = true
    },
    getI18nName(str){
      switch(str){
        case "Upload" :
          return i18n.t('UPLOADSTATUS.UPLOAD');
        case "Import" :
          return i18n.t('UPLOADSTATUS.IMPORT');
        case "PROCESSING" :
          return i18n.t('UPLOADSTATUS.PROCESSING');
        case "COMPLETE" :
          return i18n.t('UPLOADSTATUS.COMPLETE');
        case "FAIL" :
          return i18n.t('UPLOADSTATUS.FAIL');
        case "EMBEDDING" :
          return i18n.t('UPLOADSTATUS.EMBEDDING');
        case "UploadFolder" :
          return i18n.t('UPGPT.FOLDERUPLOAD');
        case "UploadQAFile" :
          return i18n.t('UPGPT.QAUPLOAD');
        case "UploadFile" :
          return i18n.t('UPGPT.FILEUPLOAD');
        case "WEB" :
          return i18n.t('UPGPT.WEBSITEANALYSIS');
        case "Contact" :
          return i18n.t('Bower_Title.Contact');
        case "Organization" :
          return i18n.t('Bower_Title.Company');
        case "Opportunity" :
          return i18n.t('Bower_Title.Oppty');
        case "Case" :
          return i18n.t('Bower_Title.Case');
        case "KnowledgeArticle" :
          return i18n.t('Bower_Title.KnowledgeArticle');
        case "Auto Sync" :
          return i18n.t('GPT_UPLOAD.AutoSync');
        case "Manual Sync" :
          return i18n.t('GPT_UPLOAD.ManualSync');
        case "UploadImage" :
          return i18n.t("UPGPT.UPLOADIMG");
        default:
          return str;
      }
    },
    rowClass() {
        const rowClass = 'dataTableColor'
        return rowClass;
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }                
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog() {
      this.messageDialog = false;
    },
    async onEmitRefresh(){
        this.loading = true;
        await this.getKnowledgeList();
        await this.getListData();
        this.loading = false;
    },
  },
  watch:{
    "selectKnowledge":async function(){
      await this.getListData();
    }
  }
};
</script>